{
  "aditional_requests": "Dodatkowe prośby",
  "inside": "W środku",
  "outside": "Na zewnątrz",
  "personal_information": "Dane osobowe",
  "no_time_slots": "Brak wolnych stolików o tej godzinie, sprawdź późniejsze terminy",
  "seating_tooltip": "Wybierz miejsce do siedzenia",
  "seating_tooltip_inside": "Restauracja oferuje wyłącznie",
  "select_date_and_guests": "Wybierz datę i liczbę gości, aby wyświetlić przedziały czasowe",
  "select_time": "Wybierz godzinę",
  "pay": "Zapłać",
  "per_person": "W przypadku tej rezerwacji restauracja wymaga gwarancji karty kredytowej. Zablokujemy Twoją kartę kredytową w kwocie {{amount}} na osobę.",
  "per_reservation": "W przypadku tej rezerwacji restauracja wymaga gwarancji karty kredytowej. Zablokujemy Twoją kartę kredytową w wysokości {{amount}} za rezerwację.",
  "min_people": "Jeśli Twoja rezerwacja obejmuje więcej niż {{min}} osób.",
  "late_cancellation_threshold_per_person": "Jeśli anulujesz rezerwację po {{threshold}}, restauracja pobierze opłatę za późniejsze anulowanie: {{amount}} za osobę.",
  "late_cancellation_threshold_per_reservation": "Jeśli anulujesz rezerwację po {{threshold}}, restauracja pobierze opłatę za późniejsze anulowanie: {{amount}} za rezerwację.",
  "more_info": "Więcej informacji",
  "transactions_text": "Należy pamiętać, że rezerwacja nie jest zakończona do momentu dokonania płatności.",
  "please_note": "Proszę to zanotować:",
  "cancel_for_free": "Możesz anulować tę rezerwację bezpłatnie do {{threshold}}",
  "no_show_charge":"W przypadku niestawienia się na rezerwację restauracja pobierze opłatę w wysokości: {{amount}}",
  "funds_release": "Twoje środki zostaną zwolnione po pomyślnej rezerwacji lub, w przypadku anulowania rezerwacji, w momencie anulowania rezerwacji. Pojawienie się transakcji odwrotnej na karcie kredytowej może zająć do 7 dni.",
  "search_from": "o",
  "people_and_guest_name_text": "dla {{count}} osóby w rezerwacji pod nazwiskiem {{name}}",
  "request_at": "Richiedi una prenotazione presso ",
  "needs_approval_title": "La tua richiesta deve essere confermata dall'attività. ",
  "needs_approval_text": "\"Riceverai un'e-mail o una telefonata dal ristorante per confermare la tua richiesta.\nDi solito {{restaurant_name}} risponde entro {{hours}} H.\"",
  "booking_requested": "Prenotazione richiesta",
  "requested_booking_at": "Hai richiesto una prenotazione presso ",
  "not_booking_title": "Questa non è una prenotazione!",
  "not_booking_text": "\"\nVerrai avvisato tramite telefono o e-mail una volta che l'attività gestirà la tua richiesta. Di solito {{restaurant_name}} risponde entro {{hours}} H.\"",
  "needs_approval_widget_title": "Wysyłasz prośbę o rezerwację. Wymaga ona zatwierdzenia przez {{restaurant_name}}.",
  "needs_approval_widget_text": " Kiedy restauracja zapozna się z Twoją prośbą, zostaniesz powiadomiony telefonicznie lub mailowo. Zazwyczaj {{restaurant_name}} odpowiada w ciągu {{hours}} godz.",
  "manage_request_title": "Zarządzaj swoją prośbą",
  "manage_request_text": "Wysłaliśmy Ci wiadomość e-mail dotyczącą Twojej prośby o rezerwację. Sprawdź swoją pocztę e-mail, jeśli chcesz zarządzać swoją prośbą."
}
