{
  "aditional_requests": "Additional requests",
  "inside": "Inside",
  "outside": "Outside",
  "personal_information": "Personal information",
  "no_time_slots": "No tables available for this search.",
  "seating_tooltip": "Choose where you want to be seated.",
  "seating_tooltip_inside": "The restaurant offers only",
  "select_date_and_guests": "Select date and guests to show time slots",
  "select_time": "Select time",
  "pay": "Pay",
  "per_person": "The restaurant requires a credit card guarantee for this reservation. We will place a hold on your credit card in the amount of {{amount}} per person.",
  "per_reservation": "The restaurant requires a credit card guarantee for this reservation. We will place a hold on your credit card in the amount of {{amount}} per reservation.",
  "min_people": "If your reservation is for more than {{min}} people.",
  "late_cancellation_threshold_per_person": "If you cancel after {{threshold}}, the restaurant will charge a late cancellation fee: {{amount}} per person.",
  "late_cancellation_threshold_per_reservation": "If you cancel after {{threshold}}, the restaurant will charge a late cancellation fee: {{amount}} per reservation.",
  "more_info": "More info",
  "transactions_text": "Please, note that your booking is not complete until payment is made.",
  "please_note": "Please, note that:",
  "cancel_for_free": "You can cancel this reservation for free until {{threshold}}",
  "no_show_charge":"If you do not show for the reservation the restaurant will charge a fee of: {{amount}}",
  "funds_release": "Your funds will be released upon successful reservation or, in the case you cancel, the moment you cancel your reservation. It may take up to 7 days for the reverse transaction to appear on your credit card.",
  "search_from": "at",
  "people_and_guest_name_text": "for {{count}} people under the name of {{name}}",
  "request_at": "Request a booking at ",
  "needs_approval_title": "Your request needs to be confirmed by the business.",
  "needs_approval_text": "You will receive an email or a phone call from the restaurant to confirm your request.\nUsually {{restaurant_name}} answers within {{hours}}h.",
  "booking_requested": "Booking requested",
  "requested_booking_at": "You have requested a booking at ",
  "not_booking_title": "This is not a reservation!",
  "not_booking_text": "You will be notified by phone or email once the business handles your request. Usually {{restaurant_name}} answers within {{hours}}h.",
  "needs_approval_widget_title": "Your request needs to be confirmed by the business.",
  "needs_approval_widget_text": "You will receive an email or a phone call from the restaurant to confirm your request.\nUsually {{restaurant_name}} answers within {{hours}}h.",
  "manage_request_title": "Manage your request",
  "manage_request_text": "We sent you an email regarding your reservation request. Please check your email if you wish to manage your request."
}
