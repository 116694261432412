{
  "aditional_requests": "Richieste aggiuntive",
  "inside": "Dentro",
  "outside": "Fuori",
  "personal_information": "Informazioni personali",
  "no_time_slots": "Nessuna tabella disponibile per questa ricerca.",
  "seating_tooltip": "Scegli dove vuoi sedere",
  "seating_tooltip_inside": "Il ristorante offre solo posti all'interno",
  "select_date_and_guests": "Seleziona la data e il numero di invitati per visualizzare le fasce orarie",
  "select_time": "Scegli un orario",
  "pay": "Paga",
  "per_person": "Il ristorante richiede una carta di credito a garanzia per questa prenotazione. Tratteremo sulla tua carta di credito l'importo di {{amount}} {currency} a persona.",
  "per_reservation": "Il ristorante richiede una carta di credito a garanzia per questa prenotazione. Tratteremo sulla tua carta di credito l'importo di {{amount}} per prenotazione.",
  "min_people": "Se la tua prenotazione è per più di {{min}} persone.",
  "late_cancellation_threshold_per_person": "Se annulli dopo il giorno {{threshold}}, il ristorante addebiterà una penale per cancellazione tardiva: {{amount}} a persona.",
  "late_cancellation_threshold_per_reservation": "Se annulli dopo il giorno {{threshold}}, il ristorante addebiterà una penale per cancellazione tardiva: {{amount}} per prenotazione.",
  "more_info": "Ulteriori informazioni",
  "transactions_text": "Tieni presente che la prenotazione non è completa finché non viene effettuato il pagamento.",
  "please_note": "Si prega di notare che:",
  "cancel_for_free": "Puoi cancellare questa prenotazione gratuitamente fino al giorno {{threshold}}",
  "no_show_charge":"Se non ti presenti alla prenotazione, il ristorante addebiterà una commissione di: {{amount}}",
  "funds_release": "I tuoi fondi verranno rilasciati al momento della prenotazione riuscita o, in caso di annullamento, nel momento in cui annulli la prenotazione. Potrebbero essere necessari fino a 7 giorni affinché la transazione inversa venga visualizzata sulla tua carta di credito.",
  "search_from": "A",
  "people_and_guest_name_text": "per {{count}} persone sotto il nome di {{name}}",
  "request_at": "Richiedi una prenotazione presso ",
  "needs_approval_title": "La tua richiesta deve essere confermata dall'attività. ",
  "needs_approval_text": "\"Riceverai un'e-mail o una telefonata dal ristorante per confermare la tua richiesta.\nDi solito {{restaurant_name}} risponde entro {{hours}} H.\"",
  "booking_requested": "Prenotazione richiesta",
  "requested_booking_at": "Hai richiesto una prenotazione presso ",
  "not_booking_title": "Questa non è una prenotazione!",
  "not_booking_text": "\"\nVerrai avvisato tramite telefono o e-mail una volta che l'attività gestirà la tua richiesta. Di solito {{restaurant_name}} risponde entro {{hours}} H.\"",
  "needs_approval_widget_title": "Questa è una richiesta di prenotazione. Richiede l'approvazione di {{restaurant_name}}",
  "needs_approval_widget_text": "\"\nRiceverai una notifica via e-mail o telefonica una volta che l'attività gestirà la tua richiesta. Di solito {{restaurant_name}} risponde entro {{hours}} H.\"",
  "manage_request_title": "Gestisci la tua richiesta",
  "manage_request_text": "Ti abbiamo inviato un'e-mail relativa alla tua richiesta di prenotazione. Controlla la tua e-mail se desideri gestire la tua richiesta."
}
