import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
    className={className}
  >

    <g id="Layer_x0020_1">
      <path
        className="st0"
        d="M32.6,21.1L32.6,21.1h-5.7V36h-3.7V21.1h-5.7l0,0l0,0c-1.1,0-1.7-0.9-1.7-1.7s0.6-1.7,1.7-1.7l0,0l0,0h15.1
     l0,0c1.1,0,1.7,0.9,1.7,1.7S33.7,21.1,32.6,21.1z M40.6,14v10.3l0,0v3.4H34V36h-3.7V24.3h0.6l0,0h6.3V14C37.1,14,40.6,14,40.6,14z
      M12.9,14v10.3h6.6l0,0h0.3V36h-3.4v-8.3H9.4v-3.4l0,0V14C9.4,14,12.9,14,12.9,14z"
      />
    </g>
  </svg>
);

Table.propTypes = {
  className: PropTypes.string,
};

Table.defaultProps = {
  className: '',
};

export default Table;
