{
  "aditional_requests": "Допълнителни бележки",
  "inside": "Вътре",
  "outside": "Вън",
  "personal_information": "Лична информация",
  "no_time_slots": "Няма свободни маси за това търсене.",
  "seating_tooltip": "Изеберете къде да бъдете настанени.",
  "seating_tooltip_inside": "Ресторантът има места само вътре",
  "select_date_and_guests": "Изберете дата и брой гости, за да видите възможните часове",
  "select_time": "Изберете час",
  "pay": "Плащане",
  "per_person": "Ресторантът изисква гаранция с кредитна карта за тази резервация в размер на {{amount}} лева на човек.",
  "per_reservation": "Ресторантът изисква гаранция с кредитна карта за тази резервация в размер на {{amount}} лева. ",
  "min_people": "Ако резервацията ви е за повече от {{min}} души.",
  "late_cancellation_threshold_per_person": "Ако анулирате след {{threshold}}, ресторантът ще начисли такса за късно анулиране: {{amount}} на човек.",
  "late_cancellation_threshold_per_reservation": "Ако анулирате след {{threshold}}, ресторантът ще начисли такса за късно анулиране: {{amount}}.",
  "more_info": "Повече информация",
  "transactions_text": "Моля, имайте предвид, че вашата резервация не е завършена, докато не бъде извършено плащането.",
  "please_note": "Моля, имайте предвид, че:",
  "cancel_for_free": "Можете да анулирате тази резервация безплатно до {{threshold}}",
  "no_show_charge":"Ако не се явите за резервацията, ресторантът ще начисли такса от: {{amount}}",
  "funds_release": "Вашите средства ще бъдат освободени при успешна резервация или, в случай че анулирате, в момента, в който анулирате резервацията си. Може да отнеме до 7 дни, докато обратната транзакция се покаже на кредитната ви карта.",
  "search_from": "в",
  "people_and_guest_name_text": "за {{count}} души на името на {{name}} ",
  "request_at": "Запитване за резервация {{at}} ",
  "needs_approval_title": "Запитването ви трябва да бъде потвърдено от бизнеса.",
  "needs_approval_text": "Ще получите имейл или телефонно обаждане, когато запитването е одобрено или отказано. Обикновено {{restaurant_name}} отговаря в рамките на {{hours}} ч.",
  "booking_requested": "Направено запитване",
  "requested_booking_at": "Вие направихте запитване за резервация {{at}} ",
  "not_booking_title": "Това не е резервация!",
  "not_booking_text": "Ще получите имейл или телефонно обаждане, когато запитването е одобрено или отказано. Обикновено {{restaurant_name}} отговаря в рамките на {{hours}} ч.",
  "needs_approval_widget_title": "Правите запитване за резервация. Необходимо е то да бъде одобрено от {{restaurant_name}}",
  "needs_approval_widget_text": "Ще получите имейл или телефонно обаждане, когато запитването е одобрено или отказано. Обикновено {{restaurant_name}} отговаря в рамките на {{hours}} ч.",
  "manage_request_title": "Анулиране на запитването.",
  "manage_request_text": "Изпратихме email относно запитването, където можете да го анулирате."
}
