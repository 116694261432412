{
  "title": "Powered by Dineout - the modern way to book",
  "book_now": "BOOK NOW",
  "book_again": "BOOK AGAIN",
  "special_offers": "Special Offers",
  "book_event": "Culinary events",
  "book_tasting": "Tasting menu",
  "mon": "Mon",
  "tue": "Tue",
  "wed": "Wed",
  "thu": "Thu",
  "fri": "Fri",
  "sat": "Sat",
  "sun": "Sun",
  "closed": "Closed",
  "terms_of_use": "TERMS OF SERVICE",
  "visit_dineout": "visit",
  "GLUTEN_KEY": "Cereals containing gluten",
  "CRUSTACEANS_KEY": "Crustaceans ",
  "EGGS_KEY": "Eggs ",
  "FISH_KEY": "Fish ",
  "PEANUT_KEY": "Peanuts ",
  "SOYBEANS_KEY": "Soybeans ",
  "MILK_KEY": "Milk ",
  "NUTS_KEY": "Nuts",
  "CELERY_KEY": "Celery ",
  "MUSTARD_KEY": "Mustard ",
  "SESAME_KEY": "Sesame seeds",
  "SULPHUR_DIOXIDE_AND_SULPHIDES_KEY": "Sulphur dioxide and sulphite",
  "LUPIN_KEY": "Lupin ",
  "MOLLUSCS_KEY": "Molluscs ",
  "restaurant_not_found": "Restaurant not found",
  "loading": "Loading...",
  "request_booking": "Booking Request"
}
