{
  "title": "Powered by Dineout - moderan način rezerviranja",
  "book_now": "REZERVIRAJTE ODMAH",
  "book_again": "REZERVIRAJ PONOVO",
  "special_offers": "Posebne ponude",
  "book_event": "Kulinarska događanja",
  "book_tasting": "Degustacijski jelovnik",
  "mon": "PON",
  "tue": "UTO",
  "wed": "SRI",
  "thu": "ČET",
  "fri": "PET",
  "sat": "SUB",
  "sun": "NED",
  "closed": "Zatvoreno",
  "terms_of_use": "Uvjeti korištenja",
  "visit_dineout": "posjetiti",
  "GLUTEN_KEY": "Žitarice koje sadrže gluten",
  "CRUSTACEANS_KEY": "Rakovi",
  "EGGS_KEY": "Jaja",
  "FISH_KEY": "Riba",
  "PEANUT_KEY": "Kikiriki",
  "SOYBEANS_KEY": "Soja",
  "MILK_KEY": "Mlijeko",
  "NUTS_KEY": "Orašasti plodovi",
  "CELERY_KEY": "Celer",
  "MUSTARD_KEY": "Senf",
  "SESAME_KEY": "Sjemenke sezama",
  "SULPHUR_DIOXIDE_AND_SULPHIDES_KEY": "Sumporni dioksid i sulfiti",
  "LUPIN_KEY": "Lupina",
  "MOLLUSCS_KEY": "Mekušci",
  "restaurant_not_found": "Restoran nije pronađen",
  "loading": "Učitavanje...",
  "request_booking": "Zahtjev za rezervaciju"
}
