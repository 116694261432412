{
  "title": "Powered by Dineout - il modo moderno di prenotare",
  "book_now": "PRENOTA ORA",
  "book_again": "PRENOTA ANCORA",
  "special_offers": "Oferty specjalne",
  "book_event": "Wydarzenia kulinarne",
  "book_tasting": "Menu degustacyjne",
  "mon": "Lun",
  "tue": "Mar",
  "wed": "Mer",
  "thu": "Gio",
  "fri": "Ven",
  "sat": "Sab",
  "sun": "Do",
  "closed": "Chiuso",
  "terms_of_use": "Condizioni di uso",
  "visit_dineout": "visita",
  "GLUTEN_KEY": "Glutine",
  "CRUSTACEANS_KEY": "Crostacei ",
  "EGGS_KEY": "Uova ",
  "FISH_KEY": "Pesce ",
  "PEANUT_KEY": "Noccioline ",
  "SOYBEANS_KEY": "Fagioli di soia ",
  "MILK_KEY": "Latte ",
  "NUTS_KEY": "Noci",
  "CELERY_KEY": "Sedano ",
  "MUSTARD_KEY": "Mostarda ",
  "SESAME_KEY": "Sesamo",
  "SULPHUR_DIOXIDE_AND_SULPHIDES_KEY": "Anidride solforosa e Solfiti",
  "LUPIN_KEY": "Lupini ",
  "MOLLUSCS_KEY": "Molluschi ",
  "restaurant_not_found": "Ristorante non trovato",
  "loading": "Caricamento...",
  "request_booking": "Richiesta di prenotazione"
}
