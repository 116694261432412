{
  "aditional_requests": "Dodatni zahtjevi",
  "inside": "Unutra",
  "outside": "Vani",
  "personal_information": "Osobni podaci",
  "seating_tooltip": "Odaberite mjesto na kojem želite sjediti.",
  "seating_tooltip_inside": "Restoran nudi samo sjedenje unutra",
  "select_date_and_guests": "Odaberite datum i goste za prikaz vremenskih odsječaka",
  "select_time": "Odaberite vrijeme",
  "pay": "Plati",
  "per_person": "Za ovu rezervaciju restoran zahtijeva jamstvo kreditnom karticom. Napravit ćemo rezervaciju sredstava na vašoj kreditnoj kartici u iznosu od {{amount}} po osobi.",
  "per_reservation": "Za ovu rezervaciju restoran zahtijeva jamstvo kreditnom karticom. Napravit ćemo rezervaciju sredstava na vašoj kreditnoj kartici u iznosu od {{amount}} po rezervaciji.",
  "min_people": "Ako je vaša rezervacija za više od {{min}} osoba.",
  "late_cancellation_threshold_per_person": "Ako otkažete nakon {{threshold}}, restoran će naplatiti naknadu za kasno otkazivanje: {{amount}} po osobi.",
  "late_cancellation_threshold_per_reservation": "Ako otkažete nakon {{threshold}}, restoran će naplatiti naknadu za kasno otkazivanje: {{amount}} po rezervaciji.",
  "more_info": "Više informacija",
  "transactions_text": "Imajte na umu da vaša rezervacija nije dovršena dok se ne izvrši uplata.",
  "please_note": "Imajte na umu da:",
  "cancel_for_free": "Ovu rezervaciju možete besplatno otkazati do {{threshold}}",
  "no_show_charge":"Ako se ne pojavite za rezervaciju, restoran će naplatiti naknadu od: {{amount}}",
  "funds_release": "Vaša će sredstva biti oslobođena nakon uspješne rezervacije ili, u slučaju otkazivanja, u trenutku otkazivanja rezervacije. Može proći do 7 dana prije nego što se povratna transakcija pojavi na vašoj kreditnoj kartici.",
  "search_from": "u",
  "people_and_guest_name_text": "za {{count}} osobe pod imenom {{name}}",
  "request_at": "Zahtjev za rezervaciju u ",
  "needs_approval_title": "Vaš zahtjev treba potvrditi restoran. ",
  "needs_approval_text": "\"Iz restorana ćete primiti e-poštu ili telefonski poziv kada obradi vaš zahtjev.\n{{restaurant_name}} obično odgovara u roku od {{hours}} h.\"",
  "booking_requested": "Zatražena rezervacija",
  "requested_booking_at": "Zatražili ste rezervaciju u ",
  "not_booking_title": "Ovo nije rezervacija! ",
  "not_booking_text": "Bit ćete obaviješteni telefonom ili e-poštom kada restoran obradi vaš zahtjev. {{restaurant_name}} obično odgovara u roku od {{hours}} h.",
  "needs_approval_widget_title": "Ovo je zahtjev za rezervaciju. Potrebna je potvrda {{restaurant_name}}",
  "needs_approval_widget_text": "\"\nBit ćete obaviješteni e-poštom ili telefonskim pozivom kada restoran obradi vaš zahtjev. {{restaurant_name}} obično odgovara u roku od {{hours}} h}.\"",
  "manage_request_title": "Upravljajte svojim zahtjevom",
  "manage_request_text": "Poslali smo vam e-mail u vezi vašeg zahtjeva za rezervaciju. Provjerite svoju e-poštu ako želite upravljati svojim zahtjevom."
}
