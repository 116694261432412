/**
 * Constants used with localStorage.get()/localStorage.set()
 */
export const USER_ID_KEY = 'userId';
export const SEARCH_LOCATION_KEY = 'searchLocation';
export const SEARCH_STATE = 'searchState';
export const BASKET_STATE = 'basketState';

/**
 * Date & Time
 */
export const dateFormat = 'DD-MM-YYYY';
export const timeFormat = 'HH:mm';
export const dateTimeFormat = 'DD-MM-YYYY HH:mm';

export const AWARDS = {
  BGN: {
    1000: 10,
    2000: 25,
    5000: 100,
  },
  PLN: {
    1000: 25,
    2000: 60,
    5000: 200,
  },
};

/**
 * ORDER TYPES
 */
export const ORDER_TYPE_GIFT_CARD = 'Gift card';
export const ORDER_TYPE_DELIVERY = 'Delivery';
export const ORDER_TYPE_TASTING_MENU = 'Tasting menu';
export const ORDER_TYPE_EVENT = 'Event';

export const ASYNC = 'asynchronous';
